<template>
    <div class="signature-dialog re_flex_center" v-if="visible">
        <div class="signature-content re_sizing re_relative re_text_c">
             
            <!-- 提示文本 -->
            <div class="re_font_16 re_margin_top_16">
                是否使用{{ coins }}金币进行补签？
            </div>
            <div class="re_font_12 re_color_red re_margin_top_8 color_red">
                补签成功后，形成连续签到可累计奖励
            </div>

            <!-- 按钮组 -->
            <div class="button-group re_absolute re_flex re_margin_top_24 re_font_14">
                <div class="re_color_light" @click="handleCancel">
                    取消
                </div>
                <div class="color_red" @click="handleConfirm">
                    立即补签
                </div>
            </div>
        </div>
    </div>   
</template>

<script>
export default {
    name: 'SignatureDialog',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        coins: {
            type: Number,
            default: 0
        }
    },
    emits: ['update:visible', 'confirm', 'cancel'],
    setup(props, { emit }) {
        const handleConfirm = () => {
            emit('confirm')
            emit('update:visible', false)
        }

        const handleCancel = () => {
            emit('cancel')
            emit('update:visible', false)
        }

        return {
            handleConfirm,
            handleCancel
        }
    }
}
</script>

<style scoped lang="less">
.signature-dialog {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 12px;
    overflow: hidden;
    z-index: 100;

    .van-dialog__content {
        padding: 24px 16px;
    }
}

.signature-content {
    width: 306px;
    height: 276px;
    background: url('../../../../assets/task/sendBg.png') no-repeat center center;
    background-size: 100% 100%;
    border-radius: 16px;
    padding-top: 150px;
    .button-group {
        border-top: 1px solid #F5F7F9;
        // padding: 12px 0 20px 0;
        height: 42px;
        line-height: 42px;
        gap: 12px;
        left: 0;
        bottom: 0px;
        width: 100%;

        div{
            width: 50%;
        }
        div:last-child{
            border-left: 1px solid #F5F7F9;
        }
    }
}
.color_red{
    color: #FC5C63;
}
</style>
